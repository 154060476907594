import React from 'react'
import Layout from '../../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'
import episodeArt from '../../images/song_art/episode2.png'

const Episode2 = () => (
  <Layout>
    <section className="hero is-primary is-primary">
      <div className="hero-body has-bg-img secondary">
        <div className="container has-text-centered">
          <span className="subtitle">you weren't expecting humans I hope</span>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container podcast is-fluid">
        <div className="columns name">
          <div className="column">
            <h1 className="is-size-4">Episode 2: Lovers World and Sky</h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <img src={episodeArt} className="episode-art" alt="Lovers World and Sky (barren planet underneath stars with vapors rising from surface)" />
            <iframe style={{border: 'none'}} src="//html5-player.libsyn.com/embed/episode/id/7764503/height/50/theme/legacy/thumbnail/yes/preload/no/direction/backward/" height="50" width="100%" scrolling="no"></iframe>
          </div>
          <div className="column is-two-thirds transcript">
            <p>
              Welcome again to Planet and Sky, a cosmic love story. In our first episode, a woman and a man were sent into a cavern on a barren world to investigate an enigma - a signal emanating into space that seemed to be telling an ancient story. They had found the environment of the cave to be surprisingly benevolent and eventually found themselves completely transformed by their experience. Meanwhile, the scientists at the base station struggled to make sense of the highly unusual data returned from the expedition – data that seemed to be telling the story of the birth of an entire world.
            </p>

            <p>
              Planet sat naked and isolated. He sensed his spherical nature and felt his profound density. He contemplated the geometrical perfection of his form and was filled with an unparalleled satisfaction. He pondered the substance of his body, the layers of crustal rock and the deep churning mantle below and relished his strength. He was an entire world, he thought. If others were to exist in this universe their proper role would be to gaze upon his magnificence.
            </p>

            <p>
              He began a song proclaiming his new found perspective,
            </p>

            <p>
              “I’m round,” he sang. “I am all there is or needs to be. My entire being surrounds me.
              A world of sand and dust, built on solid rock. I am a planet - look at me.”
            </p>

            <p>
              But his belief in his solitary existence was mistaken. He was not alone.
            </p>

            <p>
              All around him, in an inverse of his density, another being surrounded the great sphere of his body. She was almost without substance, though her reach extended outward to a great distance. Her voice could only whisper as she filled the periphery, dancing and swirling as she explored the points of contact with his surface. She felt a duty to correct Planet’s misapprehension. He was, after all, most certainly not alone.
            </p>

            <p>
              Her winds took on a musical form, then became quietly lyrical. Gently, she alerted him to the fact that he existed in relation to another.
            </p>

            <p>
              “Do you hear, lost and lonely child - another spirit whispers in the sky?
              You’re not alone, you’re not the only thing. I am with you flying high.”
            </p>

            <p>
              Planet was shaken from his inward reverie, but failed to immediately comprehend her words. He had assumed he led a solitary existence, and was suddenly confronted with the idea that he had a neighbor, and one rather difficult to ignore at that. He’d been content to dwell in solitary, spherical perfection, and could only see this intrusion as an aberration and a nuisance.
            </p>

            <p>
              “Trying to get my head around this now. There’s something else - duality?”
              “Why would I need this, some kind of parasite - messin’ with my geometry?”
            </p>

            <p>
              Perhaps if he ignored her maybe she would just go away. Still, he thought it was wise to keep an eye on her.
            </p>

            <p>
              Sky was amused by Planet’s attitude. Surely he was simply ignorant of the extent of the relationship in which he’d been placed. He was responsible for her as there was little she could do to escape his influence. She informed him of the larger sense in which he might view their combined forces.
            </p>

            <p>
              “I complete you - add a touch of class - even if my charms you cannot see.”
            </p>

            <p>
              She noticed that, although he seemed contemptuous of anything beyond himself, his gaze appeared to be fixed upon her - though it could hardly be otherwise with the way she
              was all around him at once. She addressed him again.
            </p>

            <p>
              “But something tells me you want someone - why’re you always looking at me?”
            </p>

            <p>
              Planet found that this aerial nuisance was beginning to command a significant portion of his attention and simply could no longer be ignored. He felt a need to process this information and insisted that Sky cease her intrusions while he did so.
            </p>

            <p>
              He erupted in frustration, “Now let me think!”
            </p>

            <p>
              For a long time, Planet sat quietly in deep thought. He was irritated with Sky’s flirtatious nonsense, but at the same time couldn’t help being a little curious. Would it really lessen his perfection to be enclosed in a shroud of this airy substance, shifting dynamically as he whirled about beneath the shining star that warmed them both? Then again, the movements of these winds could disturb his perfect body with grooves and channels.. The sky would hold the rising evaporated liquids and then cause them to drop as rain, creating powerful streams and rivers, possibly even accumulating into large permanent basins. These could obscure some of his best features. Even worse, from such pools of liquid all sorts of strange things might grow and emerge.
            </p>

            <p>
              It also occurred to him, however, that the thickening air could bring new colors to his landscapes, not all of them unattractive. As he turned in relation to his parent star he might gaze upon a variety of colors from fiery reds to deep indigos. The surface could evolve in various directions as well. Perhaps in time the pools of water would become seas and oceans, reflecting the glories of the colorful sky? Maybe great accumulations of condensed gases would produce billowing white clouds to contrast with the browns and grays of his mighty rocky terrain. He thought, and thought about it, and the magma in his core rumbled and churned.
            </p>

            <p>
              Finally, he thought of the idea of a companion in this long sojourn in the revolution about this star. She would soak into his skin and caress his plains. They would evolve together and create  a place that was theirs equally. If some sort of self-replicating and evolving life forms were to develop... well, that could be complicated, but it might just be beautiful in its own way. On second thought... pretty unlikely... wherever would they come from anyway?
            </p>

            <p>
              Sky just danced and sang while Planet pondered. She swooped and twirled and glided gently across his surface. Sometimes she put a little more zest into her dance and left some minor bruises. She wanted to let him know she wasn’t altogether insubstantial. Sky had plenty of time to let Planet ponder… he was a slow thinker. For a long, long time, he was silent, but still she sensed him yielding. At last he sang to her.
            </p>

            <p>
              “This is something good I know that it’s to be. I can envision harmony.
              You bring me air and rain and manna from the stars. I’ll provide the gravity.”
            </p>

            <p>
              Sky simply smiled. There was no need to formally accept his proposal, though it was good to hear that he understood the value of their inevitable union. She understood much better than he did that they had always been as one as long as she existed and as long as she would remain. She added her own simple vows, to bring him thoughtful presents in the form of the winds and rains that were her specialties and to always shield him from the worst of the harmful rays and occasional bombardment from the outer cosmos that came with being residents of a capricious universe.
            </p>

            <p>
              “I knew you’d come around, my silly ball of stone - though it took a million years to see.
              I’ll bring you special gifts that you will adore and shelter you from cosmic debris.”
            </p>

            <p>
              Planet admitted to his slowness but blamed it on the massiveness of his being - that it took a while to move when one carried so much weight. He pointed out that Sky had the benefits of being quicker and more graceful.
            </p>

            <p>
              “I can be a little dense… for that you must forgive - and you’re a flighty tart - agree?”
            </p>

            <p>
              He hoped he’d be understood by his new mate, as he hadn’t much experience at relationships to this point. He decided to lean on his deeply poetic soul and emphasize the eternal bond they were making amidst the deep void of space. He added,
            </p>

            <p>
              “…but now we’re bound forever mated in the void. One for all eternity.”
            </p>

            <p>
              It seemed to go over well with his beloved as she did a sweeping pirouette across the arc of his vision and responded in song.
            </p>

            <p>
              “I’ll love you dearly, hold you tenderly. The universe will know our bliss.”
            </p>

            <p>
              She proclaimed her joyous love and the desire to be with him always. The cosmos would witness a love beyond all measure and know their sublime happiness. What’s more, she promised wonders to come beyond his greatest imaginings.
            </p>

            <p>
              “Children will soon come forth upon our sphere - wonders follow on our kiss.”
            </p>

            <p>
              In delirious rapture the two lovers joined in proclamation of their union,
            </p>

            <p>
              “We’re round! Solid round!”
            </p>

            <p>
              Planet and Sky had found one another and were ready for a long and beautiful adventure together. A new world was born.
            </p>

            <p>
              Then… something rather unexpected was set in motion far, far away, something that was to have a significant impact upon their future…
            </p>
          </div>
        </div>
      </div>
  </section>
  </Layout>
)

export default Episode2
